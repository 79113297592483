import { FullScreenError } from "@/components/full-screen-states/error";
import { FullScreenLoading } from "@/components/full-screen-states/loading";
import { ErrorMessages } from "@/config/error-messages";
import { graphql } from "@/graphql";
import { useMutation } from "@apollo/client";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/setup/linear/callback")({
	component: RouteComponent,
});

const processLinearInstallationMutation = graphql(`
  mutation processLinearInstallation($code: String!) {
    processLinearAppInstallation(code: $code) {
      linearTeams {
        uuid
      }
    }
  }
`);

function RouteComponent() {
	const navigate = useNavigate();
	const [errorMessage, setErrorMessage] = useState<string | null>(null);
	const [processLinearInstallation] = useMutation(
		processLinearInstallationMutation,
	);

	useEffect(() => {
		const handleCallback = async () => {
			try {
				const urlParams = new URLSearchParams(window.location.search);
				const linearCode = urlParams.get("code");

				if (!linearCode) {
					setErrorMessage(ErrorMessages.GENERAL);
					return;
				}

				await processLinearInstallation({
					variables: {
						code: linearCode,
					},
				});

				void navigate({ to: "/setup/linear/team-selection" });
			} catch (error) {
				console.error(error);
				setErrorMessage(ErrorMessages.GENERAL);
			}
		};

		void handleCallback();
	}, [navigate, processLinearInstallation]);

	if (errorMessage) {
		const linearAppInstallLink =
			// biome-ignore lint: this looks better
			"https://linear.app/oauth/authorize?response_type=code&client_id=" +
			import.meta.env.VITE_LINEAR_CLIENT_ID +
			"&redirect_uri=" +
			window.location.origin +
			"/setup/linear/callback&state=SECURE_RANDOM&scope=read,write&actor=application";

		return (
			<FullScreenError
				message={errorMessage}
				actions={[
					{
						label: "Install app again",
						onClick: () => void window.open(linearAppInstallLink, "_blank"),
						variant: "destructive",
					},
				]}
			/>
		);
	}

	return <FullScreenLoading message="Connecting your Linear account..." />;
}
