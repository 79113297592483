/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SetupPrivacyImport } from './routes/setup/privacy'
import { Route as SetupDoneImport } from './routes/setup/done'
import { Route as SetupLinearCallbackImport } from './routes/setup/linear/callback'
import { Route as SetupGithubCallbackImport } from './routes/setup/github/callback'

// Create Virtual Routes

const IndexLazyImport = createFileRoute('/')()
const SetupLinearIndexLazyImport = createFileRoute('/setup/linear/')()
const SetupGithubIndexLazyImport = createFileRoute('/setup/github/')()
const SetupLinearTeamSelectionLazyImport = createFileRoute(
  '/setup/linear/team-selection',
)()

// Create/Update Routes

const IndexLazyRoute = IndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const SetupPrivacyRoute = SetupPrivacyImport.update({
  id: '/setup/privacy',
  path: '/setup/privacy',
  getParentRoute: () => rootRoute,
} as any)

const SetupDoneRoute = SetupDoneImport.update({
  id: '/setup/done',
  path: '/setup/done',
  getParentRoute: () => rootRoute,
} as any)

const SetupLinearIndexLazyRoute = SetupLinearIndexLazyImport.update({
  id: '/setup/linear/',
  path: '/setup/linear/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/setup/linear/index.lazy').then((d) => d.Route),
)

const SetupGithubIndexLazyRoute = SetupGithubIndexLazyImport.update({
  id: '/setup/github/',
  path: '/setup/github/',
  getParentRoute: () => rootRoute,
} as any).lazy(() =>
  import('./routes/setup/github/index.lazy').then((d) => d.Route),
)

const SetupLinearTeamSelectionLazyRoute =
  SetupLinearTeamSelectionLazyImport.update({
    id: '/setup/linear/team-selection',
    path: '/setup/linear/team-selection',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/setup/linear/team-selection.lazy').then((d) => d.Route),
  )

const SetupLinearCallbackRoute = SetupLinearCallbackImport.update({
  id: '/setup/linear/callback',
  path: '/setup/linear/callback',
  getParentRoute: () => rootRoute,
} as any)

const SetupGithubCallbackRoute = SetupGithubCallbackImport.update({
  id: '/setup/github/callback',
  path: '/setup/github/callback',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/setup/done': {
      id: '/setup/done'
      path: '/setup/done'
      fullPath: '/setup/done'
      preLoaderRoute: typeof SetupDoneImport
      parentRoute: typeof rootRoute
    }
    '/setup/privacy': {
      id: '/setup/privacy'
      path: '/setup/privacy'
      fullPath: '/setup/privacy'
      preLoaderRoute: typeof SetupPrivacyImport
      parentRoute: typeof rootRoute
    }
    '/setup/github/callback': {
      id: '/setup/github/callback'
      path: '/setup/github/callback'
      fullPath: '/setup/github/callback'
      preLoaderRoute: typeof SetupGithubCallbackImport
      parentRoute: typeof rootRoute
    }
    '/setup/linear/callback': {
      id: '/setup/linear/callback'
      path: '/setup/linear/callback'
      fullPath: '/setup/linear/callback'
      preLoaderRoute: typeof SetupLinearCallbackImport
      parentRoute: typeof rootRoute
    }
    '/setup/linear/team-selection': {
      id: '/setup/linear/team-selection'
      path: '/setup/linear/team-selection'
      fullPath: '/setup/linear/team-selection'
      preLoaderRoute: typeof SetupLinearTeamSelectionLazyImport
      parentRoute: typeof rootRoute
    }
    '/setup/github/': {
      id: '/setup/github/'
      path: '/setup/github'
      fullPath: '/setup/github'
      preLoaderRoute: typeof SetupGithubIndexLazyImport
      parentRoute: typeof rootRoute
    }
    '/setup/linear/': {
      id: '/setup/linear/'
      path: '/setup/linear'
      fullPath: '/setup/linear'
      preLoaderRoute: typeof SetupLinearIndexLazyImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexLazyRoute
  '/setup/done': typeof SetupDoneRoute
  '/setup/privacy': typeof SetupPrivacyRoute
  '/setup/github/callback': typeof SetupGithubCallbackRoute
  '/setup/linear/callback': typeof SetupLinearCallbackRoute
  '/setup/linear/team-selection': typeof SetupLinearTeamSelectionLazyRoute
  '/setup/github': typeof SetupGithubIndexLazyRoute
  '/setup/linear': typeof SetupLinearIndexLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexLazyRoute
  '/setup/done': typeof SetupDoneRoute
  '/setup/privacy': typeof SetupPrivacyRoute
  '/setup/github/callback': typeof SetupGithubCallbackRoute
  '/setup/linear/callback': typeof SetupLinearCallbackRoute
  '/setup/linear/team-selection': typeof SetupLinearTeamSelectionLazyRoute
  '/setup/github': typeof SetupGithubIndexLazyRoute
  '/setup/linear': typeof SetupLinearIndexLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexLazyRoute
  '/setup/done': typeof SetupDoneRoute
  '/setup/privacy': typeof SetupPrivacyRoute
  '/setup/github/callback': typeof SetupGithubCallbackRoute
  '/setup/linear/callback': typeof SetupLinearCallbackRoute
  '/setup/linear/team-selection': typeof SetupLinearTeamSelectionLazyRoute
  '/setup/github/': typeof SetupGithubIndexLazyRoute
  '/setup/linear/': typeof SetupLinearIndexLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/setup/done'
    | '/setup/privacy'
    | '/setup/github/callback'
    | '/setup/linear/callback'
    | '/setup/linear/team-selection'
    | '/setup/github'
    | '/setup/linear'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/setup/done'
    | '/setup/privacy'
    | '/setup/github/callback'
    | '/setup/linear/callback'
    | '/setup/linear/team-selection'
    | '/setup/github'
    | '/setup/linear'
  id:
    | '__root__'
    | '/'
    | '/setup/done'
    | '/setup/privacy'
    | '/setup/github/callback'
    | '/setup/linear/callback'
    | '/setup/linear/team-selection'
    | '/setup/github/'
    | '/setup/linear/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexLazyRoute: typeof IndexLazyRoute
  SetupDoneRoute: typeof SetupDoneRoute
  SetupPrivacyRoute: typeof SetupPrivacyRoute
  SetupGithubCallbackRoute: typeof SetupGithubCallbackRoute
  SetupLinearCallbackRoute: typeof SetupLinearCallbackRoute
  SetupLinearTeamSelectionLazyRoute: typeof SetupLinearTeamSelectionLazyRoute
  SetupGithubIndexLazyRoute: typeof SetupGithubIndexLazyRoute
  SetupLinearIndexLazyRoute: typeof SetupLinearIndexLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexLazyRoute: IndexLazyRoute,
  SetupDoneRoute: SetupDoneRoute,
  SetupPrivacyRoute: SetupPrivacyRoute,
  SetupGithubCallbackRoute: SetupGithubCallbackRoute,
  SetupLinearCallbackRoute: SetupLinearCallbackRoute,
  SetupLinearTeamSelectionLazyRoute: SetupLinearTeamSelectionLazyRoute,
  SetupGithubIndexLazyRoute: SetupGithubIndexLazyRoute,
  SetupLinearIndexLazyRoute: SetupLinearIndexLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/setup/done",
        "/setup/privacy",
        "/setup/github/callback",
        "/setup/linear/callback",
        "/setup/linear/team-selection",
        "/setup/github/",
        "/setup/linear/"
      ]
    },
    "/": {
      "filePath": "index.lazy.tsx"
    },
    "/setup/done": {
      "filePath": "setup/done.tsx"
    },
    "/setup/privacy": {
      "filePath": "setup/privacy.tsx"
    },
    "/setup/github/callback": {
      "filePath": "setup/github/callback.tsx"
    },
    "/setup/linear/callback": {
      "filePath": "setup/linear/callback.tsx"
    },
    "/setup/linear/team-selection": {
      "filePath": "setup/linear/team-selection.lazy.tsx"
    },
    "/setup/github/": {
      "filePath": "setup/github/index.lazy.tsx"
    },
    "/setup/linear/": {
      "filePath": "setup/linear/index.lazy.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
