import { FullScreenError } from "@/components/full-screen-states/error";
import { FullScreenLoading } from "@/components/full-screen-states/loading";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { graphql } from "@/graphql";
import { useQuery } from "@apollo/client";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";
import moveTicketToProducta from "../../assests/move-ticket-to-producta.gif";
import openProductaTeam from "../../assests/producta-team.png";

export const Route = createFileRoute("/setup/done")({
  component: RouteComponent,
});

const linearTeamQuery = graphql(`
  query linearTeam {
    viewer {
      uuid
      linearUser {
        linearOrganization {
          name
          urlKey
        }
        linearTeams {
          name
          key
          repository {
            uuid
          }
        }
      }
    }
  }
`);

function RouteComponent() {
  const { data, loading, error } = useQuery(linearTeamQuery);
  const navigate = useNavigate();

  if (loading) {
    return <FullScreenLoading />;
  }

  if (error || !data?.viewer) {
    console.error("error", error);
    console.error("data", data);
    return <FullScreenError />;
  }

  const productaInstalledTeam = data.viewer?.linearUser?.linearTeams.find(
    (team) => team.repository,
  );
  const linearOrganizationUrlKey =
    data?.viewer?.linearUser?.linearOrganization?.urlKey;

  if (!productaInstalledTeam) {
    return (
      <FullScreenError
        title="Your Linear setup is incomplete"
        message="Please go back to the Linear setup."
        actions={[
          {
            label: "Go back to setup",
            onClick: () => {
              void navigate({
                to: "/",
              });
            },
          },
        ]}
      />
    );
  }

  const linkToLinearWithProducta = `https://linear.app/${linearOrganizationUrlKey}/team/${productaInstalledTeam.key}/all`;

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center p-4 relative overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-md"
      >
        <Card className="backdrop-blur-xl">
          <CardHeader>
            <div className=" flex items-center text-2xl space-x-2">
              <div className="rounded-full bg-green-500/10 w-10 h-10 flex items-center justify-center">
                <CheckCircle className="h-5 w-5 text-green-500 " />
              </div>
              <h2>Installation Complete!</h2>
            </div>
          </CardHeader>
          <CardContent className="space-y-6">
            <h3 className="text-lg font-medium text-white">
              Here's what you can do next
            </h3>
            <div className="space-y-4">
              {productaInstalledTeam.name === "Producta" && (
                <div className="space-y-2">
                  <h4>1. Look for your Producta team in Linear</h4>
                  <img
                    src={openProductaTeam}
                    alt="Dashboard Screenshot"
                    className="rounded-md border border-white/10 h-auto w-full"
                  />
                </div>
              )}
              <div className="space-y-2">
                <h4>
                  {productaInstalledTeam.name === "Producta" ? "2" : "1"}. Add a
                  ticket to the "Producta: in progress" column
                </h4>
                <img
                  src={moveTicketToProducta}
                  alt="Tutorial GIF"
                  className="w-full h-auto rounded-md border border-white/10"
                />
              </div>
              <h3>
                {productaInstalledTeam.name === "Producta" ? "3" : "2"}. Check{" "}
                <a
                  href="https://docs.producta.ai/creating-good-tasks"
                  className="text-blue-600 underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  our docs
                </a>{" "}
                for how to create good tickets that work well with Producta.
              </h3>
            </div>
          </CardContent>
          <CardFooter>
            <a
              className="w-full"
              href={linkToLinearWithProducta}
              target="_blank"
              rel="noreferrer"
            >
              <Button size="lg" className="w-full">
                Go to Linear
              </Button>
            </a>
          </CardFooter>
        </Card>
      </motion.div>
    </div>
  );
}
