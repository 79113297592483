import { InstallCard } from "@/components/setup/setup-card";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/setup/privacy")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <InstallCard
      description={
        <div>
          First of all, we want to mention your privacy is our top priority.
          Here's how we put this into practice to protect your data:
          <span className="text-white">
            <br />
            <br />
            <strong>Your code stays private</strong>
            <ul className="list-disc pl-6">
              <li>The Producta team will not look into your code</li>
              <li>All code generated by Producta belongs to you</li>
              <li>Your code is never used for AI training</li>
            </ul>
            <br />
            <strong>You're in control</strong>
            <ul className="list-disc pl-6">
              <li>You will review all changes Producta generates</li>
              <li>You can easily revoke access to Producta at any time</li>
            </ul>
            <br />
            <strong>We operate with minimal permissions</strong>
            <ul className="list-disc pl-6">
              <li>In Linear: we read relevant tasks, to generate solutions</li>
              <li>
                In GitHub: we open pull requests, to present the solutions
              </li>
            </ul>
          </span>
        </div>
      }
      stepOrder="1"
      installLink="/setup/github"
      installButtonText="I understand"
    />
  );
}
