import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";

const router = createRouter({
	routeTree: routeTree,
});

declare module "@tanstack/react-router" {
	interface Register {
		router: typeof router;
	}
}

if (import.meta.env.PROD) {
	void import("@sentry/react").then((Sentry) => {
		Sentry.init({
			dsn: "https://d2b9fa19fd9240ca4b0ac8d82f5b4ae3@o4508393004662784.ingest.de.sentry.io/4508393007022160",
			tunnel: "https://sentry-worker.abdallah-0fc.workers.dev",
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration(),
			],
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
		});
	});
}

// biome-ignore lint: non-null-assertion
createRoot(document.getElementById("root")!).render(
	<StrictMode>
		<RouterProvider router={router} />
	</StrictMode>,
);
