import React from "react";
import { ClerkProvider, RedirectToSignUp, SignedOut } from "@clerk/clerk-react";
import { createRootRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { CLERK_PUBLISHABLE_KEY } from "../constants.ts";
import { AuthenticatedUserProvider } from "@/providers/AuthenticatedUser/index.tsx";
import { ApolloProviderWrapper } from "@/providers/apollo.tsx";
const PosthogProviderWrapper = React.lazy(() =>
  import("@/providers/posthog.tsx").then((module) => ({
    default: module.PosthogProviderWrapper,
  })),
);
const Toaster = React.lazy(() =>
  import("@/components/ui/toaster.tsx").then((module) => ({
    default: module.Toaster,
  })),
);
const Particles = React.lazy(() => import("@/components/particles.tsx"));

export const Route = createRootRoute({
  component: RootLayout,
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

function RootLayout() {
  const navigate = useNavigate();

  return (
    <>
      <ClerkProvider
        routerPush={(to) => navigate({ to })}
        routerReplace={(to) => navigate({ to, replace: true })}
        publishableKey={CLERK_PUBLISHABLE_KEY}
      >
        <ApolloProviderWrapper>
          <AuthenticatedUserProvider>
            <PosthogProviderWrapper>
              <div className="dark">
                <Outlet />
              </div>
            </PosthogProviderWrapper>
          </AuthenticatedUserProvider>
          <SignedOut>
            <RedirectToSignUp />
          </SignedOut>
        </ApolloProviderWrapper>
      </ClerkProvider>
      <Toaster />
      <Particles
        className="absolute inset-0 -z-10 bg-black"
        quantity={85}
        ease={70}
        size={0.2}
        staticity={25}
        color={"#ffffff"}
      />
      <TanStackRouterDevtools />
    </>
  );
}
