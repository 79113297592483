import type { GraphQLRequest } from "@apollo/client/core";
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client/core";
import { createHttpLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { ApolloProvider } from "@apollo/client/react/context";
import { useAuth } from "@clerk/clerk-react";
import { useMemo } from "react";

interface NetworkError {
	statusCode?: number;
}

function isAuthError(error: unknown): error is NetworkError {
	return typeof error === "object" && error !== null && "statusCode" in error;
}

const retryLink = new RetryLink({
	attempts: {
		max: 5,
		retryIf: (error: unknown) => {
			if (!isAuthError(error)) {
				return false;
			}

			return error.statusCode === 401;
		},
	},
});

const httpLink = createHttpLink({
	uri: `${import.meta.env.VITE_API_URL}/graphql`,
});

const cache = new InMemoryCache({
	typePolicies: {
		User: {
			keyFields: ["uuid"],
		},
	},
});

const client = new ApolloClient({
	link: ApolloLink.from([retryLink, httpLink]),
	cache,
});

export const ApolloProviderWrapper = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const { getToken } = useAuth();

	useMemo(() => {
		const authMiddleware = setContext(
			async (
				_: GraphQLRequest,
				{ headers }: { headers?: Record<string, string> },
			) => {
				const token = await getToken();
				return {
					headers: {
						...headers,
						authorization: token ? `Bearer ${token}` : "",
					},
				};
			},
		);

		client.setLink(
			ApolloLink.from([retryLink, authMiddleware.concat(httpLink)]),
		);
	}, [getToken]);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
