/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  UUID: { input: string; output: string; }
};

export type CreateGithubAppInstallationResponse = {
  __typename?: 'CreateGithubAppInstallationResponse';
  githubAppInstallation?: Maybe<GithubAppInstallation>;
  message?: Maybe<Scalars['String']['output']>;
  status: CreateGithubAppInstallationStatus;
};

export enum CreateGithubAppInstallationStatus {
  Success = 'SUCCESS',
  TooManyRepositories = 'TOO_MANY_REPOSITORIES'
}

export type CreateLinearTeamResponse = {
  __typename?: 'CreateLinearTeamResponse';
  linearTeam?: Maybe<LinearTeam>;
  message?: Maybe<Scalars['String']['output']>;
  status: CreateLinearTeamStatus;
};

export enum CreateLinearTeamStatus {
  Success = 'SUCCESS',
  TeamLimitReached = 'TEAM_LIMIT_REACHED'
}

export type GithubAppInstallation = {
  __typename?: 'GithubAppInstallation';
  actorId: Scalars['String']['output'];
  actorLogin: Scalars['String']['output'];
  actorType: GithubInstallationActorType;
  id: Scalars['UUID']['output'];
  isProcessed: Scalars['Boolean']['output'];
  repositories: Array<Repository>;
  user?: Maybe<User>;
};

export enum GithubInstallationActorType {
  Organization = 'Organization',
  User = 'User'
}

export type LinearOrganization = {
  __typename?: 'LinearOrganization';
  linearTeams: Array<LinearTeam>;
  linearUsers: Array<LinearUser>;
  name: Scalars['String']['output'];
  urlKey?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type LinearProject = {
  __typename?: 'LinearProject';
  description?: Maybe<Scalars['String']['output']>;
  isDeletedOnLinear: Scalars['Boolean']['output'];
  linearTeams: Array<LinearTeam>;
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type LinearTeam = {
  __typename?: 'LinearTeam';
  description?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  linearOrganization: LinearOrganization;
  linearProjects: Array<LinearProject>;
  linearUsers: Array<LinearUser>;
  name: Scalars['String']['output'];
  productaInProgressStateId?: Maybe<Scalars['String']['output']>;
  productaInReviewStateId?: Maybe<Scalars['String']['output']>;
  repository?: Maybe<Repository>;
  uuid: Scalars['UUID']['output'];
};

export type LinearTeamCreateInput = {
  linearOrganizationUuid: Scalars['UUID']['input'];
  repositoryUuid: Scalars['UUID']['input'];
};

export type LinearTeamUpdateInput = {
  repositoryUuid: Scalars['UUID']['input'];
};

export type LinearUser = {
  __typename?: 'LinearUser';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  linearOrganization?: Maybe<LinearOrganization>;
  linearTeams: Array<LinearTeam>;
  name: Scalars['String']['output'];
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateProductaInLinearTeam: LinearTeam;
  cleanUpUser: Scalars['Boolean']['output'];
  createGithubAppInstallation: CreateGithubAppInstallationResponse;
  createProductaLinearTeam: CreateLinearTeamResponse;
  processLinearAppInstallation: LinearOrganization;
  updateRepository?: Maybe<Repository>;
  updateUser?: Maybe<User>;
};


export type MutationActivateProductaInLinearTeamArgs = {
  linearTeamUuid: Scalars['UUID']['input'];
  repositoryUuid: Scalars['UUID']['input'];
};


export type MutationCleanUpUserArgs = {
  userUuid: Scalars['UUID']['input'];
};


export type MutationCreateGithubAppInstallationArgs = {
  installationId: Scalars['String']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCreateProductaLinearTeamArgs = {
  input: LinearTeamCreateInput;
};


export type MutationProcessLinearAppInstallationArgs = {
  code: Scalars['String']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUpdateRepositoryArgs = {
  input: RepositoryUpdateInput;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateUserArgs = {
  input: UserUpdateInput;
  userUuid?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  githubAppInstallation?: Maybe<GithubAppInstallation>;
  linearOrganization?: Maybe<LinearOrganization>;
  linearProject?: Maybe<LinearProject>;
  linearTeam?: Maybe<LinearTeam>;
  linearUser?: Maybe<LinearUser>;
  repository?: Maybe<Repository>;
  user?: Maybe<User>;
  viewer: User;
};


export type QueryGithubAppInstallationArgs = {
  githubAppInstallationId: Scalars['String']['input'];
};


export type QueryLinearOrganizationArgs = {
  linearOrganizationUuid: Scalars['ID']['input'];
};


export type QueryLinearProjectArgs = {
  linearProjectUuid: Scalars['ID']['input'];
};


export type QueryLinearTeamArgs = {
  linearTeamUuid: Scalars['ID']['input'];
};


export type QueryLinearUserArgs = {
  linearUserUuid: Scalars['ID']['input'];
};


export type QueryRepositoryArgs = {
  repositoryUuid: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  userClerkId?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['ID']['input']>;
};

export type Repository = {
  __typename?: 'Repository';
  description?: Maybe<Scalars['String']['output']>;
  developmentBranch?: Maybe<Scalars['String']['output']>;
  developmentBranchHeadCommitSha?: Maybe<Scalars['String']['output']>;
  githubAppInstallation?: Maybe<GithubAppInstallation>;
  githubAppInstallationId?: Maybe<Scalars['String']['output']>;
  githubRepositoryId: Scalars['Int']['output'];
  isProcessed: Scalars['Boolean']['output'];
  linearTeams: Array<LinearTeam>;
  name: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  ownerType: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['UUID']['output'];
};

export type RepositoryUpdateInput = {
  developmentBranch: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  githubAppInstallation?: Maybe<GithubAppInstallation>;
  linearUser?: Maybe<LinearUser>;
  userClerkId: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};

export type UserUpdateInput = {
  githubAppInstallationId: Scalars['String']['input'];
};

export type UserBaseFragment = { __typename: 'User', uuid: string } & { ' $fragmentName'?: 'UserBaseFragment' };

export type GetUserUuidQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserUuidQuery = { __typename?: 'Query', viewer: (
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'UserBaseFragment': UserBaseFragment } }
  ) };

export type GetAllInstallationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllInstallationsQuery = { __typename?: 'Query', viewer: (
    { __typename?: 'User', githubAppInstallation?: { __typename?: 'GithubAppInstallation', id: string } | null, linearUser?: { __typename?: 'LinearUser', id: string, linearTeams: Array<{ __typename?: 'LinearTeam', repository?: { __typename?: 'Repository', uuid: string } | null }> } | null }
    & { ' $fragmentRefs'?: { 'UserBaseFragment': UserBaseFragment } }
  ) };

export type LinearTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type LinearTeamQuery = { __typename?: 'Query', viewer: { __typename?: 'User', uuid: string, linearUser?: { __typename?: 'LinearUser', linearOrganization?: { __typename?: 'LinearOrganization', name: string, urlKey?: string | null } | null, linearTeams: Array<{ __typename?: 'LinearTeam', name: string, key: string, repository?: { __typename?: 'Repository', uuid: string } | null }> } | null } };

export type CreateGithubAppInstallationMutationVariables = Exact<{
  installationId: Scalars['String']['input'];
}>;


export type CreateGithubAppInstallationMutation = { __typename?: 'Mutation', createGithubAppInstallation: { __typename?: 'CreateGithubAppInstallationResponse', status: CreateGithubAppInstallationStatus, githubAppInstallation?: { __typename?: 'GithubAppInstallation', id: string } | null } };

export type GetGraphqlInstallationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGraphqlInstallationQuery = { __typename?: 'Query', viewer: (
    { __typename?: 'User', githubAppInstallation?: { __typename?: 'GithubAppInstallation', id: string, actorLogin: string, actorType: GithubInstallationActorType, repositories: Array<{ __typename?: 'Repository', uuid: string }> } | null }
    & { ' $fragmentRefs'?: { 'UserBaseFragment': UserBaseFragment } }
  ) };

export type ProcessLinearInstallationMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type ProcessLinearInstallationMutation = { __typename?: 'Mutation', processLinearAppInstallation: { __typename?: 'LinearOrganization', linearTeams: Array<{ __typename?: 'LinearTeam', uuid: string }> } };

export type GetLinearInstallationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLinearInstallationQuery = { __typename?: 'Query', viewer: (
    { __typename?: 'User', linearUser?: { __typename?: 'LinearUser', id: string, linearTeams: Array<{ __typename?: 'LinearTeam', repository?: { __typename?: 'Repository', uuid: string } | null }> } | null }
    & { ' $fragmentRefs'?: { 'UserBaseFragment': UserBaseFragment } }
  ) };

export type GetLinearTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLinearTeamsQuery = { __typename?: 'Query', viewer: { __typename?: 'User', uuid: string, linearUser?: { __typename?: 'LinearUser', linearOrganization?: { __typename?: 'LinearOrganization', uuid: string } | null, linearTeams: Array<{ __typename?: 'LinearTeam', uuid: string, name: string, repository?: { __typename?: 'Repository', uuid: string } | null }> } | null, githubAppInstallation?: { __typename?: 'GithubAppInstallation', repositories: Array<{ __typename?: 'Repository', uuid: string }> } | null } };

export type ActivateProductaInLinearTeamMutationVariables = Exact<{
  linearTeamUuid: Scalars['UUID']['input'];
  repositoryUuid: Scalars['UUID']['input'];
}>;


export type ActivateProductaInLinearTeamMutation = { __typename?: 'Mutation', activateProductaInLinearTeam: { __typename?: 'LinearTeam', uuid: string } };

export type CreateLinearTeamMutationVariables = Exact<{
  linearOrganizationUuid: Scalars['UUID']['input'];
  repositoryUuid: Scalars['UUID']['input'];
}>;


export type CreateLinearTeamMutation = { __typename?: 'Mutation', createProductaLinearTeam: { __typename?: 'CreateLinearTeamResponse', status: CreateLinearTeamStatus, message?: string | null } };

export const UserBaseFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]} as unknown as DocumentNode<UserBaseFragment, unknown>;
export const GetUserUuidDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getUserUUID"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserBase"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]} as unknown as DocumentNode<GetUserUuidQuery, GetUserUuidQueryVariables>;
export const GetAllInstallationsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getAllInstallations"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserBase"}},{"kind":"Field","name":{"kind":"Name","value":"githubAppInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"linearUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"repository"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]} as unknown as DocumentNode<GetAllInstallationsQuery, GetAllInstallationsQueryVariables>;
export const LinearTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"linearTeam"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"linearUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"linearOrganization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"urlKey"}}]}},{"kind":"Field","name":{"kind":"Name","value":"linearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"key"}},{"kind":"Field","name":{"kind":"Name","value":"repository"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]}}]}}]} as unknown as DocumentNode<LinearTeamQuery, LinearTeamQueryVariables>;
export const CreateGithubAppInstallationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createGithubAppInstallation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"installationId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createGithubAppInstallation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"installationId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"installationId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"githubAppInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]}}]} as unknown as DocumentNode<CreateGithubAppInstallationMutation, CreateGithubAppInstallationMutationVariables>;
export const GetGraphqlInstallationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getGraphqlInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserBase"}},{"kind":"Field","name":{"kind":"Name","value":"githubAppInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"actorLogin"}},{"kind":"Field","name":{"kind":"Name","value":"actorType"}},{"kind":"Field","name":{"kind":"Name","value":"repositories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]} as unknown as DocumentNode<GetGraphqlInstallationQuery, GetGraphqlInstallationQueryVariables>;
export const ProcessLinearInstallationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"processLinearInstallation"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"processLinearAppInstallation"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"linearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]} as unknown as DocumentNode<ProcessLinearInstallationMutation, ProcessLinearInstallationMutationVariables>;
export const GetLinearInstallationDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getLinearInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"UserBase"}},{"kind":"Field","name":{"kind":"Name","value":"linearUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"linearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"repository"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"UserBase"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"__typename"}}]}}]} as unknown as DocumentNode<GetLinearInstallationQuery, GetLinearInstallationQueryVariables>;
export const GetLinearTeamsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"getLinearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"linearUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"linearOrganization"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}},{"kind":"Field","name":{"kind":"Name","value":"linearTeams"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"repository"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"githubAppInstallation"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"repositories"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetLinearTeamsQuery, GetLinearTeamsQueryVariables>;
export const ActivateProductaInLinearTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"activateProductaInLinearTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"linearTeamUuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"repositoryUuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"activateProductaInLinearTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"linearTeamUuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"linearTeamUuid"}}},{"kind":"Argument","name":{"kind":"Name","value":"repositoryUuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"repositoryUuid"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"uuid"}}]}}]}}]} as unknown as DocumentNode<ActivateProductaInLinearTeamMutation, ActivateProductaInLinearTeamMutationVariables>;
export const CreateLinearTeamDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"createLinearTeam"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"linearOrganizationUuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"repositoryUuid"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"createProductaLinearTeam"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"ObjectValue","fields":[{"kind":"ObjectField","name":{"kind":"Name","value":"linearOrganizationUuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"linearOrganizationUuid"}}},{"kind":"ObjectField","name":{"kind":"Name","value":"repositoryUuid"},"value":{"kind":"Variable","name":{"kind":"Name","value":"repositoryUuid"}}}]}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"message"}}]}}]}}]} as unknown as DocumentNode<CreateLinearTeamMutation, CreateLinearTeamMutationVariables>;